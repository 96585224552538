<template>
  <div class="box">
    <section-header
      title="Listing vehicle information"
      title-icon="fal fa-tag"
    />
    <table class="table is-striped is-fullwidth">
      <tbody>
        <tr>
          <td>Listing price</td>
          <td>{{ listing.price ? pounds(listing.price) : '-' }}</td>
        </tr>
        <tr>
          <td>Listing mileage</td>
          <td>{{ listing.mileage ? number(listing.mileage) : '-' }}</td>
        </tr>
        <tr>
          <td>Built year</td>
          <td>{{ listing.year ? listing.year : '-' }}</td>
        </tr>
        <tr>
          <td>Listed date</td>
          <td>{{ date(listing.timestamp) }}</td>
        </tr>
      </tbody>
    </table>
    <section-header title="Listing text" title-icon="fal fa-bars" />
    <div class="content is-scroll">
      <span v-if="listing && listing.adText">
        {{ listing.adText }}
      </span>
      <span class="has-text-grey" v-else>Unavailable</span>
    </div>
  </div>
</template>

<script>
import { pounds, number } from '../../../utils/format'
import * as date from '../../../utils/date'
export default {
  name: 'ListingCard',
  components: {
    sectionHeader: () => import('../../section-header/components/SectionHeader')
  },
  methods: {
    date(value) {
      return date.format(value, 'DD-MM-YYYY')
    },
    pounds(value) {
      return pounds(value)
    },
    number(value) {
      return number(value)
    }
  },
  props: {
    listing: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="sass" scoped>
.is-scroll
  max-height: 15rem
  overflow: scroll
</style>
